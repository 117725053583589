import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress, Typography} from '@mui/material'
import {PublicationsDocument} from '../../../gql/pub.generated'
import NewsBlock from '../blocks/newsBlock/newsBlock'
import {PUBLICATIONS_CONSTS} from './publications.consts'
import styles from './publications.module.scss'
import {useLazyQuery} from '@apollo/client'
// import AdvertisementRightBlock from '../blocks/advertisement/advertisementRightBlock/advertisementRightBlock'
// import AdvertisementLowerBlock from '../blocks/advertisement/advertisementLowerBlock/advertisementLowerBlock'

const Publications: FC<any> = ({initValues}) => {
  const isEmptyList =
    initValues?.data?.publications?.edges?.length > 0 ? false : true
  // const [isAdvFooterOpenModal, setIsOpenAdvFooterModal] = useState(true)
  const [loading, setLoading] = useState(false)
  const [publications, setPublications] = useState(
    initValues?.data?.publications
  )
  const [data, setData] = useState<any>()

  useEffect(() => {
    // setIsEmptyList(
    //   initValues?.data?.publications?.edges?.length > 0 ? false : true
    // )
    setPublications(initValues?.data?.publications)
  }, [initValues])

  const pageInfo = useMemo(
    () => publications?.pageInfo,
    [publications?.pageInfo]
  )

  const [fetchMorePublications] = useLazyQuery(PublicationsDocument, {
    fetchPolicy: 'no-cache', // 'cache-and-network',
    onCompleted: data => {
      setData(data)
      setLoading(false)
    }
  })

  useEffect(() => {
    setPublications(prev => {
      if (data?.publications) {
        const newVal = JSON.parse(JSON.stringify(data.publications))
        newVal.edges = prev?.edges.concat(data.publications?.edges)

        return newVal
      }
      return prev
    })
  }, [data])

  const fetchMoreData = useCallback(async () => {
    setLoading(true)
    fetchMorePublications({
      variables: {
        first: 10,
        limit: 10,
        after:
          pageInfo.endCursor || initValues?.publications?.pageInfo.endCursor
      }
    })
  }, [pageInfo, initValues, fetchMorePublications])

  return (
    <div className={styles.publicationsWrapper}>
      <div className={styles.publicationsCentralBlock}>
        {isEmptyList === false && publications?.edges?.length > 0 && (
          <div>
            <NewsBlock
              publications={publications?.edges?.map(item => item.node) || []}
            ></NewsBlock>
            {!!pageInfo?.hasNextPage && loading === false && (
              <div className={styles.additionalOperations}>
                <button
                  role='button'
                  type='button'
                  onClick={fetchMoreData}
                  className={styles.downloadMoreBtn}
                >
                  {PUBLICATIONS_CONSTS.downloadMoreMsg}
                </button>
              </div>
            )}
          </div>
        )}
        {loading === true && (
          <div className={styles.circularProgressWrapper}>
            <CircularProgress color='primary' size={70} />
          </div>
        )}
        {isEmptyList === true && loading === false && (
          <Box marginTop='15px'>
            <Typography variant='h2'>
              {PUBLICATIONS_CONSTS.publicationListIsEmptyMsg}
            </Typography>
          </Box>
        )}
      </div>
      {/* <AdvertisementRightBlock></AdvertisementRightBlock>
      {isAdvFooterOpenModal && (
        <div className={styles.advertisementsLowerBlock}>
          <AdvertisementLowerBlock
            setIsOpenAdvFooterModal={setIsOpenAdvFooterModal}
          ></AdvertisementLowerBlock>
        </div>
      )} */}
    </div>
  )
}

export default Publications
