import {inject, observer} from 'mobx-react'
import {FC, useEffect, useState} from 'react'
import {DeviceSettingsStore} from '../../../../stores/deviceSettingsStore'
import styles from './newsBlock.module.scss'
import NewsBlockItem from './newsBlockItem/newsBlockItem'
import BigNewsBlockItem from './bigNewsBlockItem/bigNewsBlockItem'
import {getFormatedDate} from '../../../../helpers/getFormatedDate'

type NewsBlockProps = {
  deviceSettingsStore: DeviceSettingsStore
  publications: any[]
}

const createBlocks = (publications: any[]): any[] => {
  if (publications?.length) {
    const currentBlocks: any[] = []
    let blockIndex = -1

    publications.forEach((item, ind) => {
      if (ind === 0 || (ind > 1 && ind % 5 === 0)) {
        blockIndex++
        currentBlocks[blockIndex] = {
          bigNewsItem: item,
          newsItems: []
        }
      } else {
        currentBlocks[blockIndex].newsItems.push(item)
      }
    })
    return currentBlocks
  }
  return []
}

const NewsBlock: FC<any> = inject('deviceSettingsStore')(
  observer((props: NewsBlockProps) => {
    const initBlocks = createBlocks(props?.publications || [])
    const [blocks, setBlocks] = useState<any[]>(initBlocks)

    useEffect(() => {
      const newBlocks = createBlocks(props?.publications || [])
      setBlocks(newBlocks)
    }, [props?.publications])

    const [darkMode, setDarkMode] = useState<boolean>(
      props?.deviceSettingsStore?.darkMode || false
    )

    useEffect(() => {
      setDarkMode(props?.deviceSettingsStore?.darkMode || false)
    }, [props?.deviceSettingsStore?.darkMode])

    return (
      <div>
        {blocks?.map((item, ind) => (
          <div
            className={darkMode ? styles.newsBlockNight : styles.newsBlock}
            key={`blocks-item-${ind}`}
          >
            <div className={styles.bigNewsWrapper}>
              <BigNewsBlockItem
                key={item.bigNewsItem.id}
                urn={item.bigNewsItem.urn}
                publishedAt={getFormatedDate(item.bigNewsItem.publishedAt)}
                title={item.bigNewsItem.title}
                subtitle={item.bigNewsItem.subtitle}
                imageAlt={item.bigNewsItem?.announceImage?.alt || ''}
                imageSrc={
                  item.bigNewsItem?.announceImage?.figureVersion
                    ?.primaryProcessedImage?.url || ''
                }
              ></BigNewsBlockItem>
            </div>
            <div className={styles.newsWrapper}>
              {item.newsItems.map(newsItem => (
                <NewsBlockItem
                  key={newsItem.id}
                  urn={newsItem.urn}
                  publishedAt={getFormatedDate(newsItem.publishedAt)}
                  title={newsItem.title}
                  imageAlt={newsItem?.announceImage?.alt || ''}
                  imageSrc={
                    newsItem?.announceImage?.figureVersion
                      ?.primaryProcessedImage?.url
                  }
                ></NewsBlockItem>
              ))}
            </div>
          </div>
        ))}
        {/*<div className={styles.bigNewsWrapper}>
           {articles &&
            articles.map(articleItem => (
              <BigNewsBlockItem
                key={articleItem.id}
                uid={articleItem.uid}
                publishedAt={articleItem.publishedAt}
                title={articleItem.title}
                subtitle={articleItem.subtitle}
                imageAlt={articleItem?.announceImage?.alt || ''}
                imageSrc={
                  articleItem?.announceImage?.figureVersion
                    ?.primaryProcessedImage?.url || ''
                }
              ></BigNewsBlockItem>
            ))}
        </div>
        <div className={styles.newsWrapper}>
          {news &&
            news.map((newsItem, ind) => (
              <NewsBlockItem
                key={newsItem.uid}
                uid={newsItem.id}
                publishedAt={newsItem.publishedAt}
                title={newsItem.title}
                imageSrc={
                  newsItem?.announceImage?.figureVersion?.primaryProcessedImage
                    ?.url
                }
              ></NewsBlockItem>
            ))}
        </div> */}
      </div>
    )
  })
)

export default NewsBlock
