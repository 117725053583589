import * as Types from '../apollo/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type PublicationNodeFragment = {__typename?: 'Publication'} & Pick<
  Types.Publication,
  | 'id'
  | 'uid'
  | 'urn'
  | 'title'
  | 'subtitle'
  | 'seoTitle'
  | 'publishedAt'
  | 'publishedAtDate'
  | 'commentsCount'
  | 'specProjectUrl'
  | 'typeSlug'
> & {
    announceImage: {__typename?: 'AnnounceImage'} & Pick<
      Types.AnnounceImage,
      'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
    > & {
        figureVersion?: Types.Maybe<
          {__typename?: 'FigureVersion'} & FigureVersionFragmentFragment
        >
      }
    authors: Array<
      {__typename?: 'Author'} & Pick<
        Types.Author,
        'uid' | 'firstName' | 'lastName'
      >
    >
  }

export type PublicationsQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
}>

export type PublicationsQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'PublicationsEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Publication'} & PublicationNodeFragment
            >
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<
        Types.PageInfo,
        'endCursor' | 'hasNextPage'
      >
    }
  >
}

export type PublicationByUrnQueryVariables = Types.Exact<{
  urn: Types.Scalars['String']
}>

export type PublicationByUrnQuery = {__typename?: 'Query'} & {
  publicationByURN?: Types.Maybe<
    {__typename?: 'Publication'} & PublicationFieldsFragment
  >
}

export type PublicationSeoQueryVariables = Types.Exact<{
  urn: Types.Scalars['String']
}>

export type PublicationSeoQuery = {__typename?: 'Query'} & {
  publicationByURN?: Types.Maybe<
    {__typename?: 'Publication'} & Pick<
      Types.Publication,
      'title' | 'seoTitle' | 'yandexTitle' | 'subtitle' | 'keywords'
    > & {
        announceImage: {__typename?: 'AnnounceImage'} & {
          figureVersion?: Types.Maybe<
            {__typename?: 'FigureVersion'} & {
              primaryProcessedImage?: Types.Maybe<
                {__typename?: 'Image'} & Pick<Types.Image, 'url'>
              >
            }
          >
        }
      }
  >
}

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<
      Types.FigureType,
      'id' | 'uid' | 'slug'
    >
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<
      Types.FigureVersion,
      'id' | 'uid'
    > & {
        crop: {__typename?: 'Crop'} & Pick<
          Types.Crop,
          'left' | 'top' | 'width' | 'height'
        >
        primaryProcessedImage?: Types.Maybe<
          {__typename?: 'Image'} & Pick<Types.Image, 'url'>
        >
        processedImages: Array<
          {__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>
        >
      }
  }

export type PublicationFieldsFragment = {__typename?: 'Publication'} & Pick<
  Types.Publication,
  | 'id'
  | 'uid'
  | 'urn'
  | 'title'
  | 'yandexTitle'
  | 'seoTitle'
  | 'subtitle'
  | 'status'
  | 'createdAt'
  | 'redirectUrl'
  | 'typeSlug'
  | 'publishedAt'
  | 'updatedAt'
  | 'url'
  | 'oldAdminUrl'
  | 'keywords'
> & {
    antecedent?: Types.Maybe<
      {__typename?: 'Publication'} & Pick<
        Types.Publication,
        'title' | 'urn' | 'createdAt'
      >
    >
    announceImage: {__typename?: 'AnnounceImage'} & Pick<
      Types.AnnounceImage,
      'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
    > & {
        figureVersion?: Types.Maybe<
          {__typename?: 'FigureVersion'} & FigureVersionFragmentFragment
        >
      }
    socialMediaImage: {__typename?: 'SocialMediaImage'} & Pick<
      Types.SocialMediaImage,
      'id'
    > & {
        socialMediaFigureVersion?: Types.Maybe<
          {__typename?: 'SocialMediaFigureVersion'} & Pick<
            Types.SocialMediaFigureVersion,
            'title' | 'alignY'
          > & {
              announceFigureVersion: {
                __typename?: 'FigureVersion'
              } & FigureVersionFragmentFragment
              processedImage?: Types.Maybe<
                {__typename?: 'Image'} & Pick<Types.Image, 'url'>
              >
            }
        >
      }
    subscriptionBlock?: Types.Maybe<
      {__typename?: 'SubscriptionBlock'} & Pick<
        Types.SubscriptionBlock,
        'uid' | 'title'
      >
    >
    story?: Types.Maybe<
      {__typename?: 'Story'} & Pick<Types.Story, 'id' | 'uid' | 'title'>
    >
    regions?: Types.Maybe<
      Array<
        Types.Maybe<
          {__typename?: 'Region'} & Pick<Types.Region, 'id' | 'uid' | 'title'>
        >
      >
    >
    tags?: Types.Maybe<
      Array<
        Types.Maybe<
          {__typename?: 'Tag'} & Pick<Types.Tag, 'id' | 'uid' | 'title'>
        >
      >
    >
    authors: Array<
      {__typename?: 'Author'} & Pick<
        Types.Author,
        'uid' | 'firstName' | 'lastName'
      >
    >
    destinations?: Types.Maybe<
      Array<
        Types.Maybe<
          {__typename?: 'Destination'} & Pick<
            Types.Destination,
            'id' | 'slug' | 'title'
          >
        >
      >
    >
    yandexZenCategories: Array<
      {__typename?: 'YandexZenCategory'} & Pick<
        Types.YandexZenCategory,
        'id' | 'uid' | 'title'
      >
    >
    yandexNewsVideos: Array<
      {__typename?: 'PublicationYandexNewsVideo'} & Pick<
        Types.PublicationYandexNewsVideo,
        'uri' | 'title' | 'uid'
      > & {thumbnail: {__typename?: 'Figure'} & FigureFragment}
    >
    rubric?: Types.Maybe<
      {__typename?: 'Rubric'} & Pick<Types.Rubric, 'id' | 'title'>
    >
    content: {__typename?: 'PublicationContent'} & {
      elements: Array<
        | ({__typename: 'CustomHTMLElement'} & Pick<
            Types.CustomHtmlElement,
            'id' | 'uid' | 'html'
          >)
        | ({__typename: 'IFrameElement'} & Pick<
            Types.IFrameElement,
            | 'src'
            | 'title'
            | 'width'
            | 'height'
            | 'allow'
            | 'allowFullscreen'
            | 'frameborder'
            | 'id'
            | 'uid'
          >)
        | ({__typename: 'ImageElement'} & Pick<
            Types.ImageElement,
            'isFullWidth' | 'author' | 'alt' | 'description' | 'id' | 'uid'
          > & {
              figureVersion?: Types.Maybe<
                {__typename?: 'FigureVersion'} & FigureVersionFragmentFragment
              >
            })
        | ({__typename: 'TextElement'} & Pick<
            Types.TextElement,
            'id' | 'uid' | 'content'
          >)
      >
    }
  }

export type FigureVersionFragmentFragment = {
  __typename?: 'FigureVersion'
} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<
      {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    >
    crop: {__typename?: 'Crop'} & Pick<
      Types.Crop,
      'left' | 'top' | 'width' | 'height'
    >
    figure: {__typename?: 'Figure'} & Pick<
      Types.Figure,
      'id' | 'src' | 'uid'
    > & {cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>}
  }

export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const PublicationNodeFragmentDoc = gql`
  fragment PublicationNode on Publication {
    id
    uid
    urn
    title
    subtitle
    seoTitle
    publishedAt
    publishedAtDate
    commentsCount
    announceImage {
      id
      isFullWidth
      description
      author
      alt
      figureVersion {
        ...FigureVersionFragment
      }
    }
    specProjectUrl
    typeSlug
    authors {
      uid
      firstName
      lastName
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`
export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const PublicationFieldsFragmentDoc = gql`
  fragment PublicationFields on Publication {
    id
    antecedent {
      title
      urn
      createdAt
    }
    uid
    urn
    title
    yandexTitle
    seoTitle
    subtitle
    status
    createdAt
    redirectUrl
    typeSlug
    announceImage {
      id
      isFullWidth
      description
      author
      alt
      figureVersion {
        ...FigureVersionFragment
      }
    }
    socialMediaImage {
      id
      socialMediaFigureVersion {
        title
        alignY
        announceFigureVersion {
          ...FigureVersionFragment
        }
        processedImage {
          url
        }
      }
    }
    publishedAt
    updatedAt
    url
    oldAdminUrl
    keywords
    subscriptionBlock {
      uid
      title
    }
    story {
      id
      uid
      title
    }
    regions {
      id
      uid
      title
    }
    tags {
      id
      uid
      title
    }
    authors {
      uid
      firstName
      lastName
    }
    destinations {
      id
      slug
      title
    }
    yandexZenCategories {
      id
      uid
      title
    }
    subscriptionBlock {
      uid
      title
    }
    yandexNewsVideos {
      uri
      title
      uid
      thumbnail {
        ...Figure
      }
    }
    rubric {
      id
      title
    }
    content {
      elements {
        id
        uid
        __typename
        ... on ImageElement {
          isFullWidth
          author
          alt
          description
          figureVersion {
            ...FigureVersionFragment
          }
        }
        ... on TextElement {
          id
          uid
          content
        }
        ... on IFrameElement {
          src
          title
          width
          height
          allow
          allowFullscreen
          frameborder
        }
        ... on CustomHTMLElement {
          id
          uid
          html
        }
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
  ${FigureFragmentDoc}
`
export const PublicationsDocument = gql`
  query publications($after: String, $first: Int) {
    publications(
      sort: PUBLISHED_AT_DESC
      limit: 10
      first: $first
      after: $after
      filter: {typeSlug: "articles"}
    ) {
      edges {
        node {
          ...PublicationNode
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PublicationNodeFragmentDoc}
`

/**
 * __usePublicationsQuery__
 *
 * To run a query within a React component, call `usePublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePublicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublicationsQuery,
    PublicationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationsQuery, PublicationsQueryVariables>(
    PublicationsDocument,
    options
  )
}
export function usePublicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicationsQuery,
    PublicationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationsQuery, PublicationsQueryVariables>(
    PublicationsDocument,
    options
  )
}
export type PublicationsQueryHookResult = ReturnType<
  typeof usePublicationsQuery
>
export type PublicationsLazyQueryHookResult = ReturnType<
  typeof usePublicationsLazyQuery
>
export type PublicationsQueryResult = Apollo.QueryResult<
  PublicationsQuery,
  PublicationsQueryVariables
>
export const PublicationByUrnDocument = gql`
  query publicationByURN($urn: String!) {
    publicationByURN(urn: $urn) {
      ...PublicationFields
    }
  }
  ${PublicationFieldsFragmentDoc}
`

/**
 * __usePublicationByUrnQuery__
 *
 * To run a query within a React component, call `usePublicationByUrnQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationByUrnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationByUrnQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function usePublicationByUrnQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicationByUrnQuery,
    PublicationByUrnQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationByUrnQuery, PublicationByUrnQueryVariables>(
    PublicationByUrnDocument,
    options
  )
}
export function usePublicationByUrnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicationByUrnQuery,
    PublicationByUrnQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<
    PublicationByUrnQuery,
    PublicationByUrnQueryVariables
  >(PublicationByUrnDocument, options)
}
export type PublicationByUrnQueryHookResult = ReturnType<
  typeof usePublicationByUrnQuery
>
export type PublicationByUrnLazyQueryHookResult = ReturnType<
  typeof usePublicationByUrnLazyQuery
>
export type PublicationByUrnQueryResult = Apollo.QueryResult<
  PublicationByUrnQuery,
  PublicationByUrnQueryVariables
>
export const PublicationSeoDocument = gql`
  query publicationSeo($urn: String!) {
    publicationByURN(urn: $urn) {
      title
      seoTitle
      yandexTitle
      subtitle
      keywords
      announceImage {
        figureVersion {
          primaryProcessedImage {
            url
          }
        }
      }
    }
  }
`

/**
 * __usePublicationSeoQuery__
 *
 * To run a query within a React component, call `usePublicationSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationSeoQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function usePublicationSeoQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicationSeoQuery,
    PublicationSeoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationSeoQuery, PublicationSeoQueryVariables>(
    PublicationSeoDocument,
    options
  )
}
export function usePublicationSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicationSeoQuery,
    PublicationSeoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationSeoQuery, PublicationSeoQueryVariables>(
    PublicationSeoDocument,
    options
  )
}
export type PublicationSeoQueryHookResult = ReturnType<
  typeof usePublicationSeoQuery
>
export type PublicationSeoLazyQueryHookResult = ReturnType<
  typeof usePublicationSeoLazyQuery
>
export type PublicationSeoQueryResult = Apollo.QueryResult<
  PublicationSeoQuery,
  PublicationSeoQueryVariables
>
