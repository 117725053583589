import {formatInTimeZone} from 'date-fns-tz'
import {ru} from 'date-fns/locale'

export const getFormatedDate = (val): string => {
  if (val) {
    const res = formatInTimeZone(
      val,
      'Asia/Yekaterinburg',
      'dd.MM.yyyy HH:mm',
      {
        locale: ru
      }
    ).toString()
    return res
  }
  return ''
}
