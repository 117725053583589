import {inject, observer} from 'mobx-react'
import {FC, useEffect, useState} from 'react'
import styles from './bigNewsBlockItem.module.scss'
import {DeviceSettingsStore} from '../../../../../stores/deviceSettingsStore'
import Image from 'next/image'

type BigNewsBlockItemProps = {
  urn: string
  imageAlt: string
  imageSrc: string
  publishedAt: string
  title: string
  subtitle: string
  deviceSettingsStore: DeviceSettingsStore
}

const BigNewsBlockItem: FC<any> = inject('deviceSettingsStore')(
  observer((props: BigNewsBlockItemProps) => {
    const [urn, setUrn] = useState(props?.urn || '')
    const [publishedAt, setPublishedAt] = useState(props?.publishedAt || '')
    const [title, setTitle] = useState(props?.title || '')
    const [subtitle, setSubtitle] = useState(props?.subtitle || '')
    const [imageAlt, setImageAlt] = useState(props?.imageAlt || '')
    const [imageSrc, setImageSrc] = useState(props?.imageSrc || '')

    useEffect(() => {
      setUrn(props?.urn || '')
      setPublishedAt(props?.publishedAt || '')
      setTitle(props?.title || '')
      setSubtitle(props?.subtitle || '')
      setImageAlt(props?.imageAlt || '')
      setImageSrc(props?.imageSrc || '')
    }, [props])

    const [darkMode, setDarkMode] = useState<boolean>(
      props?.deviceSettingsStore?.darkMode || false
    )

    useEffect(() => {
      setDarkMode(props?.deviceSettingsStore?.darkMode || false)
    }, [props?.deviceSettingsStore?.darkMode])

    return (
      <a className={styles.bigNewsBlockItem} href={`/article/${urn}`}>
        <div className={styles.bigNewsBlockItemImageWrapper}>
          {imageSrc?.length ? (
            <Image
              alt={imageAlt}
              src={imageSrc}
              width={460}
              height={260}
              priority={false}
              style={{
                aspectRatio: '1.768',
                maxWidth: '460px'
              }}
            />
          ) : (
            <Image
              alt={imageAlt}
              src={'/img/png/no_photo_460_260.png'}
              width={460}
              height={260}
              priority={true}
            />
          )}
        </div>
        <div className={styles.bigNewsBlockItemInfo}>
          <div
            className={
              darkMode
                ? styles.bigNewsBlockItemDateNight
                : styles.bigNewsBlockItemDate
            }
          >
            {publishedAt}
          </div>
          <div
            className={
              darkMode
                ? styles.bigNewsBlockItemTitleNight
                : styles.bigNewsBlockItemTitle
            }
          >
            {title}
          </div>
          <div
            className={
              darkMode
                ? styles.bigNewsBlockItemSubtitleNight
                : styles.bigNewsBlockItemSubtitle
            }
          >
            <div>{subtitle}</div>
          </div>
        </div>
      </a>
    )
  })
)

export default BigNewsBlockItem
