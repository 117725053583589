import {inject, observer} from 'mobx-react'
import {FC, useEffect, useState} from 'react'
import styles from './newsBlockItem.module.scss'
import {DeviceSettingsStore} from '../../../../../stores/deviceSettingsStore'
import Image from 'next/image'

type NewsBlockItemProps = {
  urn: string
  imageAlt: string
  imageSrc: string
  publishedAt: string
  title: string
  deviceSettingsStore: DeviceSettingsStore
}

const NewsBlockItem: FC<any> = inject('deviceSettingsStore')(
  observer((props: NewsBlockItemProps) => {
    const [urn, setUrn] = useState(props?.urn || '')
    const [publishedAt, setPublishedAt] = useState(props?.publishedAt || '')
    const [title, setTitle] = useState(props?.title || '')
    const [imageAlt, setImageAlt] = useState(props?.imageAlt || '')
    const [imageSrc, setImageSrc] = useState(props?.imageSrc || '')

    useEffect(() => {
      setUrn(props.urn || '')
      setPublishedAt(props.publishedAt || '')
      setTitle(props.title || '')
      setImageAlt(props?.imageAlt || '')
      setImageSrc(props.imageSrc || '')
    }, [props])

    const [darkMode, setDarkMode] = useState<boolean>(
      props?.deviceSettingsStore?.darkMode || false
    )

    useEffect(() => {
      setDarkMode(props?.deviceSettingsStore?.darkMode || false)
    }, [props?.deviceSettingsStore?.darkMode])

    return (
      <a className={styles.newsBlockItem} href={`/article/${urn}`}>
        <div className={styles.newsBlockItemImageWrapper}>
          {imageSrc?.length ? (
            <Image
              alt={imageAlt}
              src={imageSrc}
              width={140}
              height={110}
              priority={false}
            />
          ) : (
            <Image
              alt={imageAlt}
              src={'/img/png/no_photo_140_110.png'}
              width={140}
              height={110}
              priority={true}
            />
          )}
        </div>
        <div className={styles.newsBlockItemInfoWrapper}>
          <div
            className={
              darkMode
                ? styles.newsBlockItemDateNight
                : styles.newsBlockItemDate
            }
          >
            {publishedAt}
          </div>
          <div
            className={
              darkMode
                ? styles.newsBlockItemTitleNight
                : styles.newsBlockItemTitle
            }
          >
            <div>{title}</div>
          </div>
        </div>
      </a>
    )
  })
)

export default NewsBlockItem
