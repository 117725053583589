import Head from 'next/head'
import Publications from '../components/ui/publications/publications'
import {GetServerSideProps} from 'next'
import {initializeApollo} from '../apollo/apollo'
import {PublicationsDocument} from '../gql/pub.generated'
// import {useEffect, useState} from 'react'

const Index = ({publications}) => {
  // const [publicationFirstValues, setPublicationFirstValues] =
  //   useState(publications)

  // useEffect(() => {
  //   setPublicationFirstValues(publications)
  // }, [publications])

  const publicationFirstValues = publications
  const canonicalUrl = process.env.REACT_APP_URA_HELP_URL
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Ура, помощь!',
    url: process.env.REACT_APP_URA_HELP_URL,
    sameAs: [
      'https://vk.com/urahelp',
      'https://t.me/urahelp',
      'https://dzen.ru/urahelp'
    ],
    logo: {
      '@type': 'ImageObject',
      url: `${process.env.REACT_APP_URA_HELP_URL}img/png/logo.png`,
      width: '134',
      height: '134'
    }
  }

  return (
    <>
      <Head>
        <title>Ура, помощь!</title>
        <link rel='canonical' href={canonicalUrl}></link>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
        />
        {/* <link
          rel='stylesheet'
          as='font'
          href='https://fonts.googleapis.com/css?family=Inter&display=swap'
        ></link> */}
        {/* <link
          rel='stylesheet'
          as='font'
          href='https://fonts.googleapis.com/css?family=Inter&display=swap'
        ></link> */}
        {/* <link
          rel='stylesheet'
          type='text/css'
          href='/pages/_app.amp.css'
        ></link> */}
        <meta
          name='description'
          content='«Ура, помощь!» - проект, который рассказывает простыми словами о сложных вещах: законах, налогах и деньгах. Мы говорим о рутине и объясняем, как жить и планировать в меняющемся мире.'
        />
      </Head>
      {publicationFirstValues && (
        <Publications initValues={publicationFirstValues}></Publications>
      )}
    </>
  )
}

export default Index

export const getServerSideProps: GetServerSideProps = async () => {
  const apolloClient = initializeApollo()

  const publications = await apolloClient.query({
    query: PublicationsDocument,
    variables: {}
  })

  return {
    props: {publications}
  }
}
